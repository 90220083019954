@import '@/styles/_shared';

.container {
  position: relative;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  background-color: transparent;
}

.iconContainer {
  position: absolute;
  top: 20%;
  left: 20%;
  transform: translate(calc(-50% + 12px), calc(-50% + 12px));
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressIcon {
  width: 100% !important;
  height: 100% !important;
  border-radius: 50%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.freeShippingText{
  max-width: 130px;
  text-align: center;
  font-weight: 500;
  font-family: $bebas;
}
